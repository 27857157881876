$(function() {
    $('.main-navigation .btn-toggle-menu').click(function(e) {
        
        e.preventDefault();

        $(this).toggleClass('active');
        
        $('.main-navigation .toggle-menu').slideToggle();
    });
});

$(function() {
    $( "li.topsubmenu" ).first().addClass( "topsubmenu" );
    $( "li.topsubmenu ul.sub-menu" ).first().addClass( "topsub-menu" );
    $( "li.topsubmenu ul.sub-menu li ul.sub-menu" ).first().addClass( "topsub-menu" );    
    $( "li.menu-item-has-children a" ).first().addClass( "open-topsubmenu" );
    
    if (window.innerWidth < 1200)  {
        $('.open-topsubmenu').click(function(e) {
            
            e.preventDefault();
            
            $('.menu-item-has-children .topsub-menu').slideToggle();
        });
    }
});


